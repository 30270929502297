import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../../context/ShopContext";
import { useParams, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import ComposerListPage from "../ComposerListPage/ComposerListPage";
import ShopHome from "../ShopHome/ShopHome";
import Projects from "../../Projects/Projects";

import BlackBanner from "../../../assets/black-banner.jpg";
import GoldBanner from "../../../assets/golden-banner.jpg";

import { INDEX_TO_TAB, TABNAME_TO_INDEX } from "../../../constants/navigation";
import { VOICES, INSTRUMENTS, CATEGORIES } from "../../../constants/general";
import Categories from "../../Categories/Categories";
import Voices from "../../Voices/Voices";
import Instruments from "../../Instruments/Instruments";

import "./ShopStyles.css";
import Collections from "../Collections/Collections";

const styles = {
  root: {
    background: "black",
  },
  input: {
    color: "gold",
    backgroundColor: "rgb(50, 50, 50)",
    marginBottom: 5,
  },
};

const Shop = ({ classes }) => {
  const {
    showModal,
    setShowModal,
    products,
    fetchProducts,
    basket,
    openCart,
  } = useContext(ShopContext);
  const { page } = useParams();

  const history = useHistory();
  const [bannerColor, setBannerColor] = useState(GoldBanner);

  const [selectedTab, setSelectedTab] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredProducts([]);
  };

  const handleChange = (e, tab) => {
    history.push(`/shop/${TABNAME_TO_INDEX[tab]}`);
    setSelectedTab(tab);
    console.log(tab);
    tab === 1 ? setBannerColor(BlackBanner) : setBannerColor(GoldBanner);
  };

  const filterProducts = (e) => {
    setSearchTerm(e.target.value);
    const array = products.filter((p) =>
      p.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProducts(array);
  };

  useEffect(() => {
    fetchProducts();
    window.scrollTo(0, 0);
    setSelectedTab(INDEX_TO_TAB[page.toUpperCase()]);
  }, [page]);

  let basketCount;
  if (basket.lineItems) {
    basketCount = <span>{basket.lineItems.length}</span>;
  }

  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bannerColor})`,
          backgroundSize: "cover",
          width: "100%",
          marginBottom: 10,
          height: 90,
        }}
      />

      <Box className='content'>
        <Box
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3>The Shop</h3>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                value={searchTerm}
                label='Quick Search...'
                InputLabelProps={{
                  style: {
                    color: "goldenrod",
                  },
                }}
                InputProps={{
                  className: classes.input,
                }}
                onChange={filterProducts}
                variant='outlined'
                color='primary'
              />
              {filteredProducts.length > 0 && (
                <Button
                  onClick={clearSearch}
                  variant='outlined'
                  color='primary'
                >
                  Clear Search
                </Button>
              )}
            </Box>
          </Box>
          {filteredProducts.length === 0 ? (
            <Box>
              <Box>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  indicatorColor='primary'
                  value={selectedTab}
                  onChange={handleChange}
                >
                  <Tab label={<span>Categories</span>} />
                  <Tab label={<span>Composers A-Z</span>} />
                  <Tab label={<span>Voices</span>} />
                  <Tab label={<span>Instruments</span>} />
                  <Tab label={<span>Collections</span>} />
                  <Tab label={<span>Shop Map </span>} />
                  <Tab label={<span>Projects </span>} />
                </Tabs>
              </Box>
              {selectedTab === INDEX_TO_TAB.MAIN && (
                <ShopHome
                  openCart={openCart}
                  basketCount={basketCount}
                  setFilteredProducts={setFilteredProducts}
                  products={products}
                  filteredProducts={filteredProducts}
                />
              )}
              {selectedTab === INDEX_TO_TAB.PROJECTS && (
                <Projects products={products} />
              )}
              {selectedTab === INDEX_TO_TAB.COMPOSERS && <ComposerListPage />}
              {selectedTab === INDEX_TO_TAB.VOICES && (
                <Voices products={products} categories={VOICES} />
              )}
              {selectedTab === INDEX_TO_TAB.INSTRUMENTS && <Instruments />}
              {selectedTab === INDEX_TO_TAB.COLLECTIONS && (
                <Collections products={products} />
              )}
              {selectedTab === INDEX_TO_TAB.CATEGORIES && (
                <Categories products={products} categories={CATEGORIES} />
              )}
            </Box>
          ) : (
            <Box>
              <h3>Search Results</h3>

              {filteredProducts.map((product) => (
                <Link to={`/product/${product.id}`}>
                  <p>{product.title}</p>
                </Link>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(Shop);
