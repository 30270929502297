const client = require("contentful").createClient({
  space: "6v1wlfltvkc2",
  accessToken: "VCAqdfbzqLqZNyY2XZcHuuZ5_KtB4e8v6U1q6Tdl2TM",
});

const getProject = (slug) =>
  client
    .getEntries({
      content_type: "project",
      "sys.id": slug,
    })
    .then((response) => response.items);
const getProjects = () =>
  client
    .getEntries({
      content_type: "project",
      order: "fields.order",
    })
    .then((response) => response.items);
const getBiog = () =>
  client
    .getEntries({ content_type: "biographyPage" })
    .then((response) => response.items);
const getRecordings = () =>
  client
    .getEntries({ content_type: "recording" })
    .then((response) => response.items);
const getRecordingsList = () =>
  client
    .getEntries({ content_type: "discographyList" })
    .then((response) => response.items);

const getShopText = () =>
  client
    .getEntries({ content_type: "shopText" })
    .then((response) => response.items);

const getHomepageText = () =>
  client
    .getEntries({ content_type: "homePage" })
    .then((response) => response.items);

const getVideos = () =>
  client
    .getEntries({ content_type: "youtubeVideo" })
    .then((response) => response.items);
const getAudioTracks = () =>
  client
    .getEntries({ content_type: "audioTrack" })
    .then((response) => response.items);

const getGoldenBook = () =>
  client
    .getEntries({ content_type: "goldenBook", order: "fields.order" })
    .then((response) => response.items);

const getComposers = () =>
  client
    .getEntries({ content_type: "composer" })
    .then((response) => response.items);

const getCollections = () =>
  client
    .getEntries({ content_type: "collections", order: "fields.order" })
    .then((response) => response.items);

const getObbligatoInstruments = () =>
  client
    .getEntries({ content_type: "obbligatoInstrument" })
    .then((response) => response.items);

const getTuttiInstruments = () =>
  client
    .getEntries({ content_type: "tuttiInstrument" })
    .then((response) => response.items);

const getAboutUs = () =>
  client
    .getEntries({ content_type: "aboutUsPage" })
    .then((response) => response.items);

const getPressQuotes = () =>
  client
    .getEntries({ content_type: "productPressReview", order: "-fields.order" })
    .then((response) => response.items);

export {
  getPressQuotes,
  getAboutUs,
  getComposers,
  getVideos,
  getAudioTracks,
  getHomepageText,
  getShopText,
  getProjects,
  getProject,
  getBiog,
  getRecordings,
  getRecordingsList,
  getGoldenBook,
  getObbligatoInstruments,
  getTuttiInstruments,
  getCollections,
};
