import React, { useContext } from "react";
import { ShopContext } from "../../context/ShopContext";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaShoppingCart,
  FaInstagram,
  FaArrowCircleRight,
  FaMinusCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";
import logo from "../../assets/logoGold.png";

import Button from "@material-ui/core/Button";

import "./Cart.css";

const Cart = (props) => {
  const {
    closeCart,
    basket,
    removeLineItemInCart,
    incrementQuantity,
    decrementQuantity,
  } = useContext(ShopContext);

  return (
    <div className={props.show ? "cart" : "cart open"}>
      <div className='text-center' style={{ padding: 30 }}>
        <img src={logo} style={{ width: 150 }} />
      </div>
      <div className='content'>
        {basket.lineItems && basket.lineItems.length > 0 ? (
          <div>
            {basket.lineItems.map((item) => (
              <div key={item.id} className='cart-lineitem'>
                <div className='cart-lineitem-details'>
                  <div>
                    <h5>
                      {item.title} ({item.quantity})
                    </h5>
                    <p>£{item.variant.price} per unit</p>
                  </div>
                  <div style={{ width: "50px" }}>
                    {item.variant.image && <img src={item.variant.image.src} />}
                  </div>
                </div>
                <div className='cart-remove-link'>
                  <div>
                    <button
                      className='quantity-button'
                      onClick={() => decrementQuantity(item)}
                    >
                      <FaMinusCircle />
                    </button>
                    <span className='quantity-button-text'>
                      {item.quantity}
                    </span>
                    <button
                      className='quantity-button'
                      onClick={() => incrementQuantity(item)}
                    >
                      <FaPlusCircle />
                    </button>
                  </div>
                  <small onClick={() => removeLineItemInCart(item.id)}>
                    remove item
                  </small>
                </div>
              </div>
            ))}

            <hr />
            <p>
              <b>Total: £{basket.totalPrice}</b>
            </p>
            <div className='cart-buttons'>
              <a
                onClick={closeCart}
                className='btn btn-dark'
                href={basket.webUrl}
                target='_blank'
              >
                Checkout
              </a>

              <Button onClick={closeCart} variant='contained' color='primary'>
                Continue Shopping
              </Button>
            </div>
          </div>
        ) : (
          <div className='empty-cart'>
            <h4>Your basket is empty</h4>
            <Link onClick={closeCart} className='btn btn-dark' to='/shop'>
              Start shopping <FaArrowCircleRight />{" "}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
