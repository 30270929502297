import React, { useContext } from "react";
import { ShopContext } from "../../context/ShopContext";
import "./HomeStyles.css";
import { Link } from "react-router-dom";
import { motion, transform } from "framer-motion";

import logo from "../../assets/logoColored.png";
import logoGold from "../../assets/logoGold.png";

import Box from "@material-ui/core/Box";

const Home = () => {
  const { comingSoon } = useContext(ShopContext);
  return (
    <Box>
      <Box className='banner'>
        <Link to={!comingSoon && "/shop/categories"}>
          <Box className='coin-box'>
            <motion.img
              initial={{ opacity: 1 }}
              animate={{ rotateY: 360, transition: { duration: 2 } }}
              transition
              src={logo}
            />
          </Box>
        </Link>
      </Box>
      <Box className='home'></Box>
    </Box>
  );
};
export default Home;
