import React, { useState, useEffect, useContext } from "react";
import { getAboutUs } from "../../contentful";
import marked from "marked";
import { ShopContext } from "../../context/ShopContext";

import { Box, Typography } from "@material-ui/core";

import "./AboutUsStyles.css";

const AboutUs = () => {
  const [content, setContent] = useState({});
  const { showModal, toggleModal } = useContext(ShopContext);

  useEffect(() => {
    getAboutUs().then((data) => setContent(data[0].fields));
  }, []);

  return (
    <Box className='content'>
      <Typography variant='h5'>About Us</Typography>
      <Box className='contentContainer'>
        <Box className='imageContainer'>
          {content.image && <img src={content.image.fields.file.url} />}
        </Box>
        <Box className='textContainer'>
          {content.text && (
            <Typography
              variant='body1'
              color='secondary'
              dangerouslySetInnerHTML={{ __html: marked(content.text) }}
            />
          )}
          <Box style={{ textAlign: "center" }}>
            <Typography>
              <b>Yannis François</b>, founder of Editions Charybde & Scylla
            </Typography>
            <Typography>
              If you would like to hire my services as a Music Consultant &
              Researcher feel free to contact me{" "}
              <span style={{ cursor: "pointer" }} onClick={toggleModal}>
                <b>here</b>
              </span>{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
