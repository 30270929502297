import React, { useState } from "react";
import marked from "marked";
import Typography from "@material-ui/core/Typography";
import "./PressPageStyles.css";

const QuoteContainer = ({ idx, review }) => {
  const [targetIdx, setTargetIdx] = useState(null);

  return (
    <div className='quote-container'>
      <div
        className='review-container'
        style={{
          height: targetIdx === idx ? "fit-content" : 150,
          overflow: "hidden",
        }}
      >
        <Typography
          variant='body1'
          color='secondary'
          style={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{
            __html: marked(review.fields.text),
          }}
        />
        {targetIdx !== idx && <div className='read-more' />}
      </div>
      <div className='read-more-toggle'>
        {targetIdx !== idx && (
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => setTargetIdx(idx)}
          >
            read more
          </Typography>
        )}
        {targetIdx === idx && (
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => setTargetIdx(null)}
          >
            read less
          </Typography>
        )}
      </div>
      <Typography variant='subtitle2'>
        {review.fields.author} |{" "}
        <a
          href={review.fields.link}
          target='_blank'
          style={{ cursor: "pointer" }}
        >
          {review.fields.publication}
        </a>
      </Typography>
      <div style={{ marginTop: 10 }} className='divider' />
    </div>
  );
};

export default QuoteContainer;
