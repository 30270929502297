import React, { useContext } from "react";
import { ShopContext } from "../../context/ShopContext";
import { FaShoppingBasket } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const Toolbar = ({ basket, drawerClickHandler }) => {
  const { comingSoon } = useContext(ShopContext);
  let basketCount;
  if (basket.lineItems) {
    basketCount = <span>{basket.lineItems.length}</span>;
  }
  return (
    <>
      <header className='toolbar'>
        <nav
          className='toolbar__navigation'
          style={{ justifyContent: comingSoon ? "center" : "space-between" }}
        >
          {!comingSoon && (
            <div>
              <DrawerToggleButton click={drawerClickHandler} />
            </div>
          )}

          <div style={{ marginTop: 20 }}>
            <Link className='link' to='/'>
              <h3>Editions Charybde & Scylla</h3>
            </Link>
            {comingSoon && (
              <div style={{ marginTop: 50 }}>
                <h2>Coming Soon...</h2>
              </div>
            )}
          </div>
          {!comingSoon && (
            <div className='toolbar_navigation-items'>
              <Link
                to='/shop/categories'
                className='special-btn link d-none d-sm-block'
              >
                <FaShoppingBasket /> <span>{basketCount}</span>
              </Link>
            </div>
          )}
        </nav>
        {!comingSoon && (
          <div className='lower-nav'>
            <Link to='/shop/categories'>Shop</Link>
            <Link to='/shop/projects'>Projects</Link>
            <Link to='/testimonials'>Livre d'Or </Link>
            <Link to='/about'>About Us</Link>
            <Link to='/press'>Press</Link>
          </div>
        )}
      </header>
    </>
  );
};

export default Toolbar;
