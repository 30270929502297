import React, { useState, useEffect } from "react";
import "./SearchBarStyles.css";
import { FaShoppingBasket } from "react-icons/fa";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { getTuttiInstruments } from "../../../contentful";

import { VOICES, INSTRUMENTS, CATEGORIES } from "../../../constants/general";

const styles = {
  root: {
    background: "black",
  },
  input: {
    color: "gold",
    backgroundColor: "rgb(50, 50, 50)",
  },
};

const SearchBar = ({
  setFilteredProducts,
  products,
  basketCount,
  openCart,
  classes,
}) => {
  const [instruments, setInstruments] = useState([]);
  useEffect(() => {
    getTuttiInstruments().then((data) => setInstruments(data));
  }, []);
  const viewAll = () => {
    setFilteredProducts(products);
  };

  const filter = (e) => {
    setFilteredProducts(
      products.filter((p) =>
        p.description.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <Box className='search-bar'>
      <Button onClick={viewAll} variant='outlined' color='primary'>
        View All
      </Button>
      <select onChange={filter}>
        <option value='all'>Filter by Voice</option>

        {VOICES.sort().map((voice) => (
          <option value={voice}>{voice}</option>
        ))}
      </select>
      <select onChange={filter}>
        <option value='all'>Filter by Instrument</option>
        {instruments.sort().map((voice) => (
          <option value={voice.fields.name}>{voice.fields.name}</option>
        ))}
      </select>
      <select onChange={filter}>
        <option value='all'>Filter by Category</option>

        {CATEGORIES.sort().map((voice) => (
          <option value={voice.code}>{voice.name}</option>
        ))}
      </select>
      <TextField
        placeholder='Type to search...'
        InputProps={{
          className: classes.input,
        }}
        variant='outlined'
        color='primary'
        onChange={filter}
      />
      <div>
        <FaShoppingBasket onClick={openCart} size={25} />{" "}
        <span style={{ marginLeft: 5 }}>{basketCount}</span>
      </div>
    </Box>
  );
};

export default withStyles(styles)(SearchBar);
