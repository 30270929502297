import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "../../context/ShopContext";
import { FaShoppingBasket } from "react-icons/fa";
import logo from "../../assets/logoGold.png";

const ProductCard = ({ item }) => {
  const { addItemToCart, openCart } = useContext(ShopContext);

  return (
    <div className='product-card '>
      <div className='main-box'>
        <div className='product-card-text'>
          <p>
            <strong>{item.title}</strong>
          </p>
          {item.images[0] ? (
            <img src={item.images[0].src} style={{ width: "100%" }} />
          ) : (
            <img src={logo} style={{ width: 150 }} />
          )}
        </div>

        <p>£{item.variants[0].price}</p>
      </div>
      <div style={{ marginTop: 10, display: "flex", flexDirection: "column" }}>
        <Link className='btn btn-dark' to={`/product/${item.id}`}>
          more info
        </Link>
        <button
          className='btn btn-light'
          onClick={() => {
            addItemToCart(item.variants[0].id, 1);
            openCart();
          }}
        >
          add to basket <FaShoppingBasket />
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
