import React from "react";
import ProductCard from "../ProductCard";
import SearchBar from "../SearchBar/SearchBar";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

const ShopHome = ({
  openCart,
  basketCount,
  setFilteredProducts,
  products,
  filteredProducts,
}) => {
  return (
    <>
      <SearchBar
        openCart={openCart}
        basketCount={basketCount}
        setFilteredProducts={setFilteredProducts}
        products={products}
      />

      <Box className='shop-display'>
        {filteredProducts.length > 0 &&
          filteredProducts.map((item) => (
            <ProductCard key={item.id} item={item} />
          ))}
      </Box>
    </>
  );
};

export default ShopHome;
