import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ShopProvider from "./context/ShopContext";
import SimpleReactLightbox from "simple-react-lightbox";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#E0A526",
    },
    secondary: {
      main: "rgb(200, 200, 200)",
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <ShopProvider>
      <BrowserRouter>
        <SimpleReactLightbox>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </SimpleReactLightbox>
      </BrowserRouter>
    </ShopProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
