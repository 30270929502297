import React, { useContext, useEffect, useReducer } from "react";
import "./ComposerListPageStyles.css";
import { ShopContext } from "../../../context/ShopContext";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { ALPHABET } from "../../../constants/general";

const reducer = (state, action) => {
  switch (action.type) {
    case "set-composers":
      return {
        ...state,
        comps: action.payload,
        filter: action.letter,
        filtered: true,
      };
    case "show-all":
      return {
        ...state,
        filter: "",
        filtered: false,
      };
  }
};
const ComposerListPage = () => {
  const { composers, allComposers } = useContext(ShopContext);
  const [state, dispatch] = useReducer(reducer, {
    comps: composers,
    filter: "",
    filtered: false,
  });

  const dynamicABC = [...new Set(composers.sort().map((c) => c[0]))];

  const filterComposers = (e) => {
    dispatch({ type: "show-all" });
    if (e.target.value === "default") {
      dispatch({ type: "show-all" });
    } else {
      const filtered = composers.filter((c) => c[0] === e.target.value);
      console.log(e.target.value);
      dispatch({
        type: "set-composers",
        payload: filtered,
        letter: e.target.value,
      });
    }
  };

  const showAll = () => {
    dispatch({ type: "show-all" });
  };

  return (
    <>
      <Box className='page-content'>
        <h3>Select a composer</h3>
        <div style={{ display: "flex", alignItems: "center" }}>
          <select value={state.filter} onChange={filterComposers}>
            <option value='default'>Filter by letter</option>
            {dynamicABC.map((c) => (
              <option value={c}>{c.toUpperCase()}</option>
            ))}
          </select>
          <Button variant='outlined' color='primary' onClick={showAll}>
            Show all
          </Button>
        </div>
        <div
          className={
            state.filtered ? "filtered-composers open" : "filtered-composers"
          }
        >
          <h4>
            Showing all composers starting with {state.filter.toUpperCase()}{" "}
          </h4>
          {state.comps.map((c) => (
            <Link to={`/composer/${c}`}>
              {" "}
              <p>{c}</p>
            </Link>
          ))}
        </div>
        <Box className='abc-display'>
          {composers &&
            ALPHABET.map((letter) => {
              const sorted = composers.filter(
                (c) => c[0].toLowerCase() === letter
              );
              if (sorted.length > 0)
                return (
                  <Box mt={5}>
                    <div className='letter'>
                      <span>{letter}</span>
                    </div>
                    {sorted.map((c) => (
                      <Link to={`/composer/${c}`}>
                        {" "}
                        <p>{c}</p>
                      </Link>
                    ))}
                  </Box>
                );
            })}
        </Box>
      </Box>
    </>
  );
};

export default ComposerListPage;
