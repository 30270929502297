export const INDEX_TO_TAB = {
  MAIN: 5,
  PROJECTS: 6,
  COMPOSERS: 1,
  CATEGORIES: 0,
  COLLECTIONS: 4,
  VOICE: 2,
  INSTRUMENTS: 3,
};

export const TABNAME_TO_INDEX = {
  0: "categories",
  1: "composers",
  2: "voices",
  3: "instruments",
  4: "collections",
  5: "main",
  6: "projects",
};
