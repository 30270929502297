import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../../context/ShopContext";
import { Link, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const ComposerPage = () => {
  const { composer } = useParams();
  const [works, setWorks] = useState([]);
  const { products, fetchProducts } = useContext(ShopContext);

  useEffect(() => {
    fetchProducts();
    if (products)
      setWorks(
        products.filter(
          (p) => p.description.includes(composer) || p.productType === composer
        )
      );
  }, []);

  return (
    <Box className='page-content'>
      <h4>All works by </h4>
      <h3>{composer}</h3>
      <hr />
      {works.map((score) => (
        <Link to={`/product/${score.id}`}>
          {" "}
          <h5>{score.title}</h5>
        </Link>
      ))}
      <hr />
      <Link to='/shop/composers'>
        {" "}
        <Button variant='outlined' color='primary'>
          to composers' A-Z
        </Button>
      </Link>
    </Box>
  );
};

export default ComposerPage;
