import React, { useState, useEffect, useContext } from "react";
import "./ProjectsStyles.css";
import { getProjects } from "../../contentful";
import { Link } from "react-router-dom";
import marked from "marked";
import ProjectCard from "../ProjectCard";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

const Projects = ({ products }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects().then((data) => setProjects(data));
  }, []);

  return (
    <>
      <div className='inner-container'>
        {products &&
          projects.map((project) => {
            const details = marked(project.fields.artists);
            const codes = project.fields.productCodes.toLowerCase().split(",");
            const linkedScores = [];
            codes.map((code) => {
              products.map((p) => {
                if (
                  p.description.toLowerCase().includes(code) &&
                  p.description
                    .toLowerCase()
                    .includes(project.fields.title.toLowerCase())
                ) {
                  linkedScores.push(p);
                }
              });
            });
            return (
              <div className='project-card'>
                {project.fields.comingSoon && <h2>Coming Soon!</h2>}
                <div className='inner-container'>
                  <div className='image-container'>
                    <img src={project.fields.image.fields.file.url} />
                  </div>
                  <div className='textContainer'>
                    <h2>{project.fields.title}</h2>{" "}
                    <section dangerouslySetInnerHTML={{ __html: details }} />
                    <p>{project.fields.labelDetails}</p>
                    <div className='divider' />
                    {!project.fields.comingSoon && (
                      <div>
                        {linkedScores.length > 0 && (
                          <p style={{ color: "gold" }}>
                            Available scores from this project
                          </p>
                        )}
                        {linkedScores.map((score) => (
                          <Link to={`/product/${score.id}`}>
                            {" "}
                            <p className='title'>{score.title}</p>
                          </Link>
                        ))}
                      </div>
                    )}
                    {project.fields.comingSoon &&
                      project.fields.programmePlaceholder && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked(project.fields.programmePlaceholder),
                          }}
                        />
                      )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Projects;
