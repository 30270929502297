import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCollections } from "../../../contentful";
import "./CollectionsStyles.css";
import Button from "@material-ui/core/Button";

const Collections = ({ products }) => {
  const [collections, setCollections] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    getCollections().then((data) => setCollections(data));
  }, []);

  const filterProducts = (collection) => {
    setSelectedCollection(collection.fields.title);
    const array = [];
    collection.fields.productCodes.split(",").map((code) =>
      products.map((product) => {
        if (product.description.includes(code)) {
          array.push(product);
        }
      })
    );
    console.log(array);
    setFilteredProducts(array);
  };

  const clearSelection = () => {
    setSelectedCollection(null);
    setFilteredProducts([]);
  };

  return (
    <div className='page-content'>
      <h3>Collections</h3>
      <div className='divider' />
      {!selectedCollection &&
        collections.map((collection) => (
          <div className='collection-name'>
            <h4 onClick={() => filterProducts(collection)}>
              {collection.fields.title}
            </h4>
          </div>
        ))}

      {selectedCollection && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <h4>{selectedCollection} </h4>
          <Button color='primary' variant='outlined' onClick={clearSelection}>
            Clear selection
          </Button>
        </div>
      )}
      {filteredProducts.map((product) => (
        <Link to={`/product/${product.id}`}>
          {" "}
          <p>{product.title}</p>
        </Link>
      ))}
    </div>
  );
};

export default Collections;
