import React, { useContext, useState } from "react";
import { ShopContext } from "../context/ShopContext";
import Button from "@material-ui/core/Button";

const style = {
  minHeight: "50px",
  color: "white",
  background: "black",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
};
const Footer = (props) => {
  const { comingSoon, handleLogin } = useContext(ShopContext);

  const handleInput = (e) => {
    if (e.target.value === "y@nn!s") handleLogin();
  };

  return (
    <div style={style}>
      <small>
        Designed & developed by Adam Woolf @{" "}
        <a style={{ color: "white" }} href='https://www.webspinner.eu'>
          WebSpinner.eu
        </a>
      </small>
      {comingSoon && (
        <div style={{ display: "flex" }}>
          <input placeholder='Login' onChange={handleInput} />
        </div>
      )}
    </div>
  );
};

export default Footer;
