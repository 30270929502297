import React, { useState, useContext, useEffect } from "react";
import { ShopContext } from "./context/ShopContext";
import { Route, Switch } from "react-router-dom";

import "./App.css";
import Home from "./components/Home/Home";

import Shop from "./components/shop/ShopMain/Shop";
import Backdrop from "./components/Backdrop/Backdrop";
import SideDrawer from "./components/Sidedrawer/SideDrawer";
import Toolbar from "./components/Toolbar/Toolbar";

import Footer from "./components/Footer";
import SingleProductPage from "./components/shop/SingleProductPage/SingleProductPage";
import Cart from "./components/shop/Cart";
import ContactBar from "./components/ContactBar";
import ComposerPage from "./components/shop/ComposerPage/ComposerPage";
import Projects from "./components/Projects/Projects";
import GoldenBook from "./components/GoldenBook/GoldenBook";
import AboutUs from "./components/AboutUs/AboutUs";
import PressPage from "./components/PressPage/PressPage";

function App() {
  const {
    cartOpen,
    openCart,
    closeCart,
    fetchProducts,
    basket,
    comingSoon,
  } = useContext(ShopContext);

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const backdropClickHandler = () => {
    setMenuOpen(false);
    closeCart();
  };

  const sideDrawerOpen = () => {
    setMenuOpen(true);
  };

  const drawerToggleClickHandler = () => {
    if (menuOpen) setMenuOpen(false);
    if (!menuOpen) {
      setMenuOpen(true);
    }
  };

  let backdrop;
  if (cartOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  return (
    <div>
      <Toolbar
        basket={basket}
        openCart={openCart}
        drawerClickHandler={drawerToggleClickHandler}
      />
      <SideDrawer show={menuOpen} click={drawerToggleClickHandler} />
      <Cart show={cartOpen} />
      {backdrop}

      <main style={{ marginTop: "176px" }}></main>
      <Switch>
        <Route path='/' exact component={Home} />

        <Route
          path='/projects'
          exact
          component={!comingSoon ? Projects : Home}
        />
        <Route
          path='/product/:id'
          component={!comingSoon ? SingleProductPage : Home}
        />
        <Route path='/shop/:page' component={!comingSoon ? Shop : Home} />
        <Route
          path='/composer/:composer'
          component={!comingSoon ? ComposerPage : Home}
        />

        <Route path='/testimonials' component={GoldenBook} />
        <Route path='/about' component={AboutUs} />
        <Route path='/press' component={PressPage} />
      </Switch>

      <ContactBar />
      <Footer />
    </div>
  );
}

export default App;
