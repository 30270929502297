export const ALPHABET = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const VOICES = [
  "High Soprano",
  "Soprano",
  "Mezzo-soprano",
  "Alto",
  "Tenor",
  "Baritenor",
  "Baritone",
  "Bass",
];

export const DUETS = [
  { name: "Soprano & Soprano", searchTerm: "S,S" },
  { name: "Soprano & Alto", searchTerm: "S,A" },
  { name: "Soprano & Tenor", searchTerm: "S,T" },
  { name: "Soprano & Bass", searchTerm: "S,B" },
  { name: "Alto & Tenor", searchTerm: "A,T" },
  { name: "Alto & Bass", searchTerm: "A,B" },
  { name: "Alto & Alto", searchTerm: "A,A" },
  { name: "Tenor & Tenor", searchTerm: "T,T" },
  { name: "Tenor & Bass", searchTerm: "T,B" },
  { name: "Bass & Bass", searchTerm: "B,B" },
];

export const THREE_AND_MORE = [
  { name: "Solo Voice & Choir", searchTerm: "" },
  { name: "Trio", searchTerm: "" },
  { name: "Quartetto", searchTerm: "" },
  { name: "Choir", searchTerm: "SATB" },
];

export const CATEGORIES = [
  { name: "Arias", code: "ECS-A" },
  { name: "Motets", code: "ECS-Mo" },
  { name: "Operas", code: "ECS-Op" },
  { name: "Oratorios", code: "ECS-Or" },
  { name: "Solo Cantatas", code: "ECS-C" },
  { name: "Overtures", code: "ECS-Ov" },
  { name: "Balli", code: "ECS-B" },
  { name: "Instrumental", code: "ECS-I" },
];
