import React, { useState, useEffect } from "react";
import "./GoldenBookStyles.css";
import { getGoldenBook } from "../../contentful";
import marked from "marked";

import Box from "@material-ui/core/Box";

import GoldBanner from "../../assets/golden-banner.jpg";

const GoldenBook = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    getGoldenBook().then((data) => setContent(data));
  }, []);
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${GoldBanner})`,
          backgroundSize: "cover",
          width: "100%",
          marginBottom: 10,
          height: 90,
        }}
      />
      <Box className='inner-container'>
        {content.map((entry) => {
          const review = marked(entry.fields.review);

          return (
            <Box className='review-card'>
              <Box>
                {entry.fields.image && (
                  <>
                    <Box>
                      <img
                        className='portrait-wrapper'
                        src={entry.fields.image.fields.file.url}
                      />
                    </Box>
                    <small style={{ color: "goldenrod" }}>
                      {entry.fields.image.fields.title}
                    </small>
                  </>
                )}
              </Box>

              <Box className='text-wrapper'>
                <Box dangerouslySetInnerHTML={{ __html: review }} />
                <h5>{entry.fields.client}</h5>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default GoldenBook;
