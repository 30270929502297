import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../context/ShopContext";
import { Link } from "react-router-dom";
import "./InstrumentsStyles.css";
import { getObbligatoInstruments, getTuttiInstruments } from "../../contentful";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const Instruments = () => {
  const { products } = useContext(ShopContext);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [term, setTerm] = useState("");
  const [obbligatoInstruments, setObbligatoInstruments] = useState([]);
  const [tuttiInstruments, setTuttiInstruments] = useState([]);

  useEffect(() => {
    getTuttiInstruments().then((data) => setTuttiInstruments(data));
    getObbligatoInstruments().then((data) => setObbligatoInstruments(data));
  }, []);

  const filterProducts = (instrument) => {
    setTerm(instrument);
    const array = [...products];
    const filtered = array.filter(
      (score) =>
        score.description
          .toLowerCase()
          .includes(`${instrument.toLowerCase()}-obbligato`) ||
        score.description
          .toLowerCase()
          .includes(`${instrument.toLowerCase()}-obbligata`)
    );
    setFilteredProducts(filtered);
  };

  const filterProductsAny = (instrument) => {
    setTerm(instrument);
    const array = [...products];
    const filtered = array.filter((score) =>
      score.description.toLowerCase().includes(instrument.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <div className='page-content'>
      <div>
        {filteredProducts.length > 0 && (
          <>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Results for pieces including {term}</h5>
              <Button
                onClick={() => setFilteredProducts([])}
                color='secondary'
                variant='outlined'
              >
                Clear Search
              </Button>
            </Box>
            <hr />
            {filteredProducts.map((score) => (
              <Link to={`/product/${score.id}`}>
                <h4>{score.title}</h4>
              </Link>
            ))}
          </>
        )}
      </div>
      {filteredProducts.length === 0 && (
        <>
          <h3>Obligato Instruments</h3>
          <div className='window'>
            {obbligatoInstruments &&
              obbligatoInstruments.sort().map((inst) => (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => filterProducts(inst.fields.name)}
                >
                  {inst.fields.name}
                </p>
              ))}
          </div>

          <h3> ALL INSTRUMENTS</h3>
          <div className='window'>
            {tuttiInstruments &&
              tuttiInstruments.sort().map((inst) => (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => filterProductsAny(inst.fields.name)}
                >
                  {inst.fields.name}
                </p>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Instruments;
