import React, { useState } from "react";
import "./VoicesStyles.css";
import Button from "@material-ui/core/Button";
import logo from "../../assets/logoBlack.png";
import { Link } from "react-router-dom";
import Duets from "../shop/Duets/Duets";

const Voices = ({ categories, products }) => {
  const [selectedCat, setSelectedCat] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showDuets, setShowDuets] = useState(false);
  const [show3AndMore, setShow3AndMore] = useState(false);

  const handleSelect = (cat) => {
    setSelectedCat(cat);
    setFilteredProducts(products.filter((p) => p.description.includes(cat)));
  };

  const selectDuets = (duet) => {
    setSelectedCat(duet.name);
    setFilteredProducts(
      products.filter((p) => p.description.includes(duet.searchTerm))
    );
  };

  const clearFilter = () => {
    setFilteredProducts([]);
    setSelectedCat(null);
  };

  return (
    <div className='page-content'>
      {!showDuets && (
        <>
          {" "}
          <h3>Voices</h3>
          <div className='coins-container'>
            {categories.map((cat) => (
              <div
                onClick={() => handleSelect(cat)}
                className={!selectedCat ? "cat-card" : "cat-card small"}
              >
                <img src={logo} />
                <h5>{cat}</h5>
              </div>
            ))}

            <div
              onClick={() => setShowDuets(true)}
              className={!selectedCat ? "cat-card" : "cat-card small"}
            >
              <img src={logo} />
              <h5>Duets</h5>
            </div>
            <div
              onClick={() => handleSelect("3 & More")}
              className={!selectedCat ? "cat-card" : "cat-card small"}
            >
              <img src={logo} />
              <h5>3 & More...</h5>
            </div>
          </div>
        </>
      )}
      {showDuets && (
        <Duets
          selectedCat={selectedCat}
          handleSelect={selectDuets}
          setShowDuets={setShowDuets}
        />
      )}

      {selectedCat && (
        <div className='text-center' style={{ marginTop: 30 }}>
          <Button onClick={clearFilter} variant='outlined' color='primary'>
            clear selection
          </Button>
          <hr />
        </div>
      )}
      {selectedCat && (
        <div className='filtered-titles'>
          <h3> {selectedCat}</h3>
          {filteredProducts.map((prod) => (
            <Link to={`/product/${prod.id}`}>
              {" "}
              <h5>
                <span> {prod.title},</span>{" "}
                <span style={{ color: "goldenrod" }}>{prod.productType}</span>
              </h5>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Voices;
