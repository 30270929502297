import React, { useContext } from "react";
import { ShopContext } from "../context/ShopContext";
import { Modal } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import logo from "../assets/logoColored.png";

import Button from "@material-ui/core/Button";
import Textfield from "@material-ui/core/Textfield";

const ContactBar = () => {
  const { showModal, toggleModal } = useContext(ShopContext);
  return (
    <div className='contact-bar'>
      <Button onClick={toggleModal} color='primary' variant='contained'>
        Contact Me
      </Button>
      <FaInstagram size={25} style={{ position: "absolute", right: 20 }} />

      <Modal show={showModal}>
        <div className='contact-form'>
          <div style={{ width: 200, margin: "auto", marginBottom: 30 }}>
            <img src={logo} style={{ width: "100%" }} />
          </div>
          <h3>Contact Us</h3>

          <form
            action='https://formspree.io/f/xvodkpgd'
            method='POST'
            className='form-group '
          >
            Name:
            {/* <Textfield
              variant='outlined'
              label='Name'
              className='form-control'
              name='name'
            /> */}
            <input className='form-control' type='text' />
            Email:{" "}
            <input className='form-control' type='email' name='_replyto' />
            Message:{" "}
            <textarea name='message' rows={10} className='form-control' />
            <div style={{ marginTop: 20 }}>
              <Button
                style={{ marginRight: 10 }}
                type='submit'
                variant='contained'
                color='primary'
              >
                Send
              </Button>
              <Button onClick={toggleModal} variant='outlined' color='primary'>
                Close
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ContactBar;
