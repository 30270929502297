import React, { useContext, useEffect, useState } from "react";
import "./SingleProductPageStyles.css";
import { getAudioTracks, getVideos } from "../../../contentful";
import { useParams, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { ShopContext } from "../../../context/ShopContext";
import { FaShoppingCart, FaArrowLeft, FaShoppingBasket } from "react-icons/fa";
import marked from "marked";
import { SRLWrapper } from "simple-react-lightbox";

import Client from "shopify-buy";

import Spinner from "../../Spinner/Spinner";

const client = Client.buildClient({
  domain: "editions-charybde-scylla.myshopify.com",
  storefrontAccessToken: "826bac45d08e1d8febec8e3fa00cd4bd",
});

const SingleProductPage = () => {
  const { id } = useParams();

  const fetchProduct = async (id) => {
    await client.product.fetch(id);
    setProduct(await client.product.fetch(id));
    console.log(await client.product.fetch(id));
  };
  const { productImages, openCart, addItemToCart } = useContext(ShopContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProduct(id);
    product && console.log(product.variants);
    getAudioTracks().then((data) => setAudioTracks(data));
    getVideos().then((data) => setVideos(data));
  }, []);

  const optionSelect = (e) => {
    setSelectedOption(e.target.value);
    // setSelectedOptionIndex(e.target.value);
    console.log(e.target.value);
  };

  let selectingLogic;

  if (product)
    selectingLogic = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          alignItems: "start",
        }}
      >
        <select onChange={optionSelect}>
          <option value='null'>Please Select</option>
          {product.variants &&
            product.variants.map((option, i) => (
              <option value={option.id}>
                {option.title} : €{option.price}
              </option>
            ))}{" "}
        </select>
        {/* {product.variants && (
          <h4>€{product.variants[selectedOptionIndex].price}</h4>
        )} */}
        <button
          disabled={selectedOption === "none" || !selectedOption}
          style={{ marginTop: "15px" }}
          className='btn btn-dark'
          onClick={() => {
            addItemToCart(selectedOption, 1);
            openCart();
          }}
        >
          {selectedOption === "none" || !selectedOption
            ? "Please select an option"
            : "Add To Cart"}
        </button>
      </div>
    );

  if (product && product.variants.length === 1)
    selectingLogic = (
      <>
        <p>€{product.variants[0].price}</p>
        <button
          className='btn btn-light'
          onClick={() => {
            addItemToCart(product.variants[0].id, 1);
            openCart();
          }}
        >
          add to basket <FaShoppingBasket />
        </button>
      </>
    );

  const linkedTracks =
    product && audioTracks
      ? audioTracks.filter((t) =>
          product.description.includes(t.fields.productCode)
        )
      : null;

  const linkedVideo =
    product && videos
      ? videos.filter((v) => {
          return (
            product.description.includes(v.fields.productCode) &&
            product.description.includes(v.fields.title)
          );
        })
      : null;

  if (!product) {
    return <Spinner />;
  }

  if (product) {
    let desc;
    if (product.description) desc = marked(product.descriptionHtml);

    return (
      <div className='page-contents'>
        <div className='inner-container'>
          <Row>
            {product && (
              <Col md={6}>
                <SRLWrapper>
                  {product.images && (
                    <div
                      style={{
                        minHeight: 500,
                        display: "flex",
                        marginBottom: 30,
                      }}
                    >
                      <div style={{ width: 400 }}>
                        {selectedImage && (
                          <img style={{ width: "100%" }} src={selectedImage} />
                        )}
                        {product.images.length > 0 && !selectedImage && (
                          <img
                            style={{ width: "100%" }}
                            src={product.images[selectedOptionIndex].src}
                          />
                        )}
                      </div>
                      <div style={{ width: 100 }}>
                        {product.images.map((image) => (
                          <img
                            onMouseEnter={() => setSelectedImage(image.src)}
                            src={image.src}
                            style={{
                              width: "100%",
                              margin: 6,
                              cursor: "pointer",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </SRLWrapper>

                {linkedTracks &&
                  linkedTracks.map((track) => (
                    <audio controls>
                      <source src={track.fields.mp3.fields.file.url} />
                    </audio>
                  ))}
              </Col>
            )}

            <Col md={6}>
              {product && (
                <div>
                  {linkedVideo &&
                    linkedVideo.map((video) => {
                      let code = marked(video.fields.youtubeCode);
                      return (
                        <section
                          className='video'
                          dangerouslySetInnerHTML={{ __html: code }}
                        />
                      );
                    })}
                  <div className='info-1'>
                    <h2>{product.title}</h2>

                    <Link to={`/composer/${product.productType}`}>
                      {" "}
                      <h5>{product.productType}</h5>
                    </Link>
                    {selectingLogic}
                    <button className='btn btn-dark' onClick={openCart}>
                      show basket <FaShoppingBasket />
                    </button>
                  </div>

                  <div>
                    <p>INSTANT DOWNLOAD: PDF</p>

                    <p>DELIVERY</p>
                    <ul>
                      <li>Immediately downloadable via link in email.</li>
                    </ul>

                    <small>
                      We don't accept returns, exchanges or cancellations. But
                      please contact us if you have any problems with your
                      order.
                    </small>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <section dangerouslySetInnerHTML={{ __html: desc }} />

          <div style={{ marginTop: 25 }}>
            <Link to='/shop/categories' className='btn btn-dark'>
              <FaArrowLeft /> Back to all products
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default SingleProductPage;
