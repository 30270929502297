import React, { useState, useEffect, createContext, useReducer } from "react";
import { getComposers } from "../contentful";
import Client from "shopify-buy";

const ShopContext = createContext(null);

const client = Client.buildClient({
  domain: "editions-charybde-scylla.myshopify.com",
  storefrontAccessToken: "826bac45d08e1d8febec8e3fa00cd4bd",
});

export const reducer = (state, action) => {
  switch (action.type) {
    case "set-products":
      return { ...state, products: action.payload };
    case "set-composers":
      return {
        ...state,
        composers: action.payload,
        allComposers: action.payload,
      };
    case "set-product":
      return {
        ...state,
        product: action.payload,
        productImages: action.images,
      };

    case "set-basket":
      return { ...state, basket: action.payload };

    case "cart-open":
      return { ...state, cartOpen: action.payload };

    case "number-of-items":
      return { ...state, numberOfItems: action.payload };

    case "add-item":
      return {
        ...state,
        basket: action.payload,
        numberOfItems: state.numberOfItems + 1,
      };

    case "remove-item":
      return {
        ...state,
        basket: action.payload,
        numberOfItems: state.numberOfItems - 1,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        showModal: !state.showModal,
      };

    case "show-modal":
      return { ...state, showModal: action.payload };

    case "handle-login":
      return { ...state, comingSoon: false };

    default:
      return state;
  }
};

const ShopProvider = (props) => {
  const toggleModal = () => {
    dispatch({ type: "TOGGLE_MODAL" });
  };

  const [state, dispatch] = useReducer(reducer, {
    products: [],
    product: {},
    productImages: [],
    composers: [],
    allComposers: [],
    basket: {},
    cartOpen: false,
    numberOfItems: 0,
    comingSoon: true,
  });

  useEffect(() => {
    getComposers().then((data) =>
      dispatch({
        type: "set-composers",
        payload: data.map((c) => c.fields.composer),
      })
    );
    createCheckout();
  }, []);

  const createCheckout = async () => {
    const checkout = await client.checkout.create();
    dispatch({ type: "set-basket", payload: checkout });

    // console.log(checkout);
  };

  const fetchCheckout = (id) => {
    client.checkout.fetch(id).then((checkout) => {
      dispatch({ type: "set-basket", payload: checkout });
    });
  };

  const fetchProducts = async () => {
    const products = await client.product.fetchAll(250);
    dispatch({ type: "set-products", payload: products });
    console.log(products);
  };

  const fetchProductWithId = async (id) => {
    const product = await client.product.fetch(id);
    dispatch({ type: "set-product", payload: product, images: product.images });

    console.log(product);
  };

  const closeCart = () => dispatch({ type: "cart-open", payload: false });

  const openCart = () => dispatch({ type: "cart-open", payload: true });

  const addItemToCart = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    const checkout = await client.checkout.addLineItems(
      state.basket.id,
      lineItemsToAdd
    );
    dispatch({ type: "add-item", payload: checkout });
  };

  const removeLineItemInCart = async (lineItemId) => {
    await client.checkout
      .removeLineItems(state.basket.id, lineItemId)
      .then((res) => {
        dispatch({ type: "remove-item", payload: res });
      });
  };
  const updateQuantity = async (lineItemId, quantity) => {
    const checkoutId = state.basket.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: +quantity }];

    return await client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        dispatch({ type: "set-basket", payload: res });
        //   console.log(lineItemsToUpdate)
      });
  };

  const decrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity - 1;
    updateQuantity(itemId, updatedQuantity);
    dispatch({ type: "number-of-items", payload: state.numberOfItems - 1 });
    // console.log(this.state.numberOfItems)
  };

  const incrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity + 1;
    updateQuantity(itemId, updatedQuantity);
    // setNumberOfItems(numberOfItems + 1)
    dispatch({ type: "number-of-items", payload: state.numberOfItems + 1 });
  };

  const handleLogin = () => {
    dispatch({ type: "handle-login" });
  };

  return (
    <ShopContext.Provider
      value={{
        products: state.products,
        product: state.product,
        fetchProducts,
        fetchProductWithId,
        cartOpen: state.cartOpen,
        openCart,
        closeCart,
        addItemToCart,
        basket: state.basket,
        removeLineItemInCart,
        incrementQuantity,
        decrementQuantity,
        showModal: state.showModal,
        toggleModal,
        composers: state.composers,
        productImages: state.productImages,
        comingSoon: state.comingSoon,
        handleLogin,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
const MainConsumer = ShopContext.Consumer;
export { MainConsumer, ShopContext };

export default ShopProvider;
