import React from "react";
import { Link } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";

import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";
import Logo from "../../assets/logoColored.png";

import "./SideDrawer.css";

const sideDrawer = (props) => {
  return (
    <Drawer anchor='left' open={props.show} onClose={props.click}>
      <div className='side-drawer'>
        <div style={{ padding: "15px" }} className='toolbar__toggle-button'>
          <DrawerToggleButton click={props.click} />
        </div>
        <ul>
          <div>
            <li>
              <Link onClick={props.click} to='/'>
                <div className='header'>
                  <img src={Logo} style={{ width: 150 }} />
                  <div style={{ marginTop: 10, textAlign: "center" }}>
                    {" "}
                    <h5>Editions Charybde </h5>
                    <h5>& Scylla</h5>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link className='custom-nav-link' onClick={props.click} to='/'>
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={props.click}
                className='custom-nav-link'
                to='/shop/categories'
              >
                Shop{" "}
              </Link>
            </li>

            {/* <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/shop/composers'
              >
                Composers List
              </Link>
            </li> */}
            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/shop/projects'
              >
                Projects
              </Link>
            </li>

            <li>
              <Link className='custom-nav-link' onClick={props.click} to='/'>
                Livre d'Or
              </Link>
            </li>
            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/about'
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                className='custom-nav-link'
                onClick={props.click}
                to='/press'
              >
                Press
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </Drawer>
  );
};

export default sideDrawer;
