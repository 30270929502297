import React, { useState } from "react";
import "./CategoriesStyles.css";
import Button from "@material-ui/core/Button";
import logo from "../../assets/logoGold.png";
import { Link } from "react-router-dom";
import banner from "../../assets/golden-banner.jpg";

const Categories = ({ categories, products }) => {
  const [selectedCat, setSelectedCat] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleSelect = (cat) => {
    setSelectedCat(cat.name);
    setFilteredProducts(
      products.filter((p) => p.description.includes(cat.code))
    );
  };

  const clearFilter = () => {
    setFilteredProducts([]);
    setSelectedCat(null);
  };

  return (
    <div className='page-content'>
      <h3>Categories</h3>
      <div className='coins-container'>
        {categories.map((cat) => (
          <div
            onClick={() => handleSelect(cat)}
            className={!selectedCat ? "cat-card" : "cat-card small"}
          >
            <img src={logo} />
            <h5>{cat.name}</h5>
          </div>
        ))}
      </div>
      {selectedCat && (
        <div className='text-center' style={{ marginTop: 30 }}>
          <Button onClick={clearFilter} variant='outlined' color='primary'>
            clear selection
          </Button>
          <hr />
        </div>
      )}

      {selectedCat && (
        <div className='filtered-titles'>
          <h3> {selectedCat}</h3>
          {filteredProducts.map((prod) => (
            <Link to={`/product/${prod.id}`}>
              {" "}
              <h5>
                <span> {prod.title},</span>{" "}
                <span style={{ color: "goldenrod" }}>{prod.productType}</span>
              </h5>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Categories;
