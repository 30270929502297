import React from "react";
import logo from "../../../assets/logoPurple.png";
import { DUETS } from "../../../constants/general";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const Duets = ({ selectedCat, handleSelect, setShowDuets }) => {
  return (
    <div>
      <h3>Duets</h3>
      <div className='coins-container'>
        {DUETS.map((duet) => (
          <div
            onClick={() => handleSelect(duet)}
            className={!selectedCat ? "cat-card" : "cat-card small"}
          >
            <img src={logo} />
            <h5>{duet.name}</h5>
          </div>
        ))}
      </div>
      <Box mt={4} style={{ textAlign: "center" }}>
        <Button
          onClick={() => setShowDuets(false)}
          variant='contained'
          color='primary'
        >
          Back to all voices
        </Button>
      </Box>
    </div>
  );
};

export default Duets;
