import React, { useState, useEffect } from "react";
import { getPressQuotes } from "../../contentful";
import marked from "marked";
import "./PressPageStyles.css";

import { Box, Typography } from "@material-ui/core";
import QuoteContainer from "./QuoteContainer";

const PressPage = () => {
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    getPressQuotes().then((data) => setQuotes(data));
  }, []);

  return (
    <Box className='content'>
      <Typography variant='h5'>Press Reviews</Typography>
      {quotes.map((quote) => (
        <Box
          style={{
            marginTop: 30,
            margin: "auto",
            padding: 20,
            display: "flex",
            flexWrap: "wrap",
            maxWidth: 1400,
          }}
        >
          {quote.fields.cdCover && (
            <img
              src={quote.fields.cdCover.fields.file.url}
              style={{ height: 200, width: 200 }}
            />
          )}
          <Box ml={2} style={{ width: 1000 }}>
            <Typography variant='h6'>{quote.fields.title}</Typography>
            {quote.fields.reviews &&
              quote.fields.reviews
                // .filter((a, b) => (a.fields.order < b.fields.order ? -1 : 1))
                .map((review, idx) => (
                  <QuoteContainer idx={idx} review={review} />
                ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PressPage;
